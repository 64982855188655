.inspection {
  width: 100%;
  height: 340px;

  .in-up {
    width: 100%;
    background-color: #d2d2d2;
    height: 20px;
    margin-bottom: 10px;

    .in-up-co {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .up-model {
        display: flex;
        width: 230px;
        justify-content: flex-start;
        align-items: center;

        .up-yellow {
          display: inline-block;
          width: 3px;
          height: 16px;
          background-color: #ffad26;
          margin-top: 2px;
        }

        .up-con {
          display: inline-block;
          padding-left: 5px;
          height: 16px;
          font-weight: bold;
        }
      }


    }
  }

  .in-down {
    height: 300px;
    background-color: #ffffff;

    .one-item-co {
      display: flex;
      justify-content: space-between;
      //width: 80%;
    }

    .down-two {
      display: flex;
      justify-content: space-between;

      .part * {
        width: 230px;
      }
    }
  }
}