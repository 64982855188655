 .re-content{
    margin-left: 15px;
   margin-right: 15px;
   margin-top: 10px;
 }



  .co-title {
     position: relative;
     top: 5px;
   span{
     display: inline-block;
     font-weight: bold;
     font-size: 16px;
     line-height: 16px;
     margin-left: 5px;
   }
    .ic{
      width: 3px;
      height: 18px;
      margin-right: 8px;
      background-color: #ffad26;
    }
  }

  .cm{
    //position: relative;
    margin-top: 10px;
  }
