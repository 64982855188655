.dt {
  position: relative;
  width: 45px;
  height: 152px;

  .ts-ri {
    position: absolute;
    width: 45px;
  }

  .ts-ax {
    position: absolute;
    height: 152px;
    left: 17px;
  }

  .ts-le {
    position: absolute;
    width: 45px;
    top: 138px
  }

  .ts-rin {
    position: absolute;
    top: 19px;
    width: 45px;
  }

  .ts-len {
    position: absolute;
    width: 45px;
    top: 119px
  }

  .ts-title {
    position: absolute;
    top: -20px;
    left: 10px;

  }

  .ts-num-ri {
    font-size: 12px;
    position: absolute;
    top: -3px;
    left: 18px;
  }

  .ts-num-le {
    font-size: 12px;
    position: absolute;
    left: 18px;
    top: 135px
  }

  .ts-num-n-ri {
    font-size: 12px;
    position: absolute;
    top: 18px;
    left: 18px;
  }

  .ts-num-n-le {
    font-size: 12px;
    position: absolute;
    left: 18px;
    top: 117px
  }
}