.ty-ecc {
  padding: 16px 28px;
  .ty-ecc-color{
    img{
      width: 750px;
    height: 30px;
    }
  }
  .ty-ecc-con {
    width: 90%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: center;
    position: relative;
    margin: auto;

    .ecc-lf {
      width: 35%;
      margin-top: 20px;
      text-align: left;
    }

    .ecc-rf {
      width: 35%;
      margin-top: 20px;
      text-align: right;

    }

    .ecc-lb {
      width: 35%;
      margin-top: 110px;
      text-align: left;

    }

    .ecc-rb {
      width: 35%;
      margin-top: 110px;
      text-align: right;

    }
    .ecc-cen {
      position: absolute;
      margin-top: 20px;
      left: 272px;
    }
  }
}

