.unit-wear{
  margin-top: 5px;
  margin-left: 105px;
  font-size: 8px;
  font-size: 8px;

  .ec-tire{
    width: 150px;
    height: 28px;
  }
}