.reports{
  display: flex;
  min-height: 100%;
  .point{
    display: inline-block;
    margin-right: 8px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
  }
}