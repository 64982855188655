body{
  background-color: #f9f9f9;
}
.lor-report{
  width: 800px;
  background-color: #fff;
  margin: auto;
  //height: 1000px;

}




