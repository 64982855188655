.ts {
  position: relative;
  width: 45px;
  height: 152px;
  //background-color: #21ffa9;

  .ts-ri {
    position: absolute;
    width: 45px;

  }
  .ts-ax{
    position: absolute;
    height: 152px;
    left: 17px;
  }
  .ts-le {
    position: absolute;
    width: 45px;
    top:138px
  }
  .ts-title{
    position: absolute;
    top: -20px;
    left: 10px;

  }
  .ts-num-ri{
    font-size: 12px;
    position: absolute;
    top: -3px;
    left: 18px;
  }
    .ts-num-le{
      font-size: 12px;
     position: absolute;
     left: 18px;
      top:135px
  }
}