.report {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  height: 2750px;
  font-size: 8px;
  background: #f0f2f5;
  .card {
    position: relative;
    width: 830px;
    min-height: 1123px;
    background: #fff;
    padding: 16px;
    height: 2750px;
  }
  &-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
     .qr {
       position: relative;
       width: 108px;
       text-align: center;
      }
  }
  &-item {
    padding-left: 28px;
    padding-right: 28px;
    &.section-0 {
      display: none;
    }
    &.section-1 {
      position:relative;
      padding: 0;
      .info {
        width: 100%;
        display: flex;
         .item1 {
          width: 50%;
          margin-bottom: 4px;
          font-size: 8px;
          .tit {
            text-align: right;
            display: inline-block;
          }
          .cnt {
            padding-left: 16px;
          }
        }
        .item {
          width: 50%;
          margin-bottom: 4px;
          font-size: 14px;
          font-weight: bold;
          .tit {
            color: #ffad26;
            text-align: right;
            display: inline-block;
          }
          .cnt {
            padding-left: 16px;
          }
        }
        &.small {
          .item {
            font-size: 12px;
            .tit {
              color: rgba(0, 0, 0, 0.65);
            }
          }
        }
      }
    }
    &.section-2 {
      background: #fff4e1;
      border-radius: 4px;
      padding: 16px 28px;
      //height: 200px;
      .cnt {
        font-size: 7px;
      }
    }
    &.section-4 {
      padding-top: 16px;
      padding-bottom: 16px;
      display: block;
       #last{
        font-size: 6px !important;
        color: #170def;
        .last-warn{
          display: inline-block;
          width: 12px;
          height: 12px;
          background-color: #170def;
          margin-right: 5px;
        }
      }
      .block {
        width: 500px;
        height: 350px;
        margin: auto;
        position: relative;
        text-align: center;
      }
      .car {
        position: relative;
        width: 120px;
        height: 250px;
        z-index: 10;
      }
      .car-info {
        position: absolute;
        &_card {
          width: 140px;
          padding: 8px 8px 0;
          text-align: left;
          .desc {
            position: absolute;
            top: 0;
            width: 160px;
            &_label {
              // display: inline-block;
              width: 60px;
              text-align: right;
            }
          }
        }
      }
      .car-tyre {
        text-align: center;
        .tyre {
          width: 80%;
        }
      }
      .car-point {
        display: flex;
        justify-content: space-around;
      }
    }
    &.section-5 {
      .block {
        width: 500px;
        margin: auto;
        position: relative;
        text-align: center;
        top: 30px;
      }
      .frame {
            position: relative;
            width: 120px;
            height: 250px;
            margin-top: -47px;
            z-index: 999;
      }
      .tyre {
        position: absolute;
        display: inline-block;
        width: 30px;
        height: 60px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .desc {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 310px;
          text-align: left;
          height: 70px;
          p{
            width: 300px !important;
          }
        }
      }
    }
    &.section-6{
      display: block;
      margin-top:30px;
    }
    &.section-7 {
      display: block;
      top:20px;
      .head-bottom{
        margin-top: 10px;
        position: relative;
        .head-bottom-right{
          position:absolute;
          left: 400px;
          top: 0px;
        }
        .tit {
          color: #ffad26;
          text-align: right;
          display: inline-block;
          font-size: 16px;
          font-weight: bold;
        }
        .cnt {
          padding-left: 16px;
          font-size: 16px;
          font-weight: bold;
        }
      }
      .block {
        width: 500px;
        margin: auto;
        position: relative;
        text-align: center;
        
      }
      // .injery { position: relative};

      
      .car {
        position: relative;
        width: 120px;
        height: 250px;
        top: 30px;
        //left: 189px;
        z-index: 10;
      }
    }
    &.section-8{
       //position: absolute;
       margin-top: 20px;

    }
    &.section-9{
       margin-top: 30px;
       .black{position: relative;
              height: 300px;
       .dot-car{
         width: 120px;
         height: 250px;
         position: absolute;
         top: 50px;
         left: 305px;
       }
       .dot-year{
         width: 250px;
       }
       .dot-lf{
         position: absolute;
         left: 20px;
         top: 10px;
       }
       .dot-rf{
       position: absolute;
       left: 465px;
       top: 10px;
     }
       .dot-lb{
         position: absolute;
         left: 20px;
         top: 200px;
       }
       .dot-rb{
       position: absolute;
       left: 465px;
       top: 200px;
     }

       }
     }
    &.section-10{
      display: block;
      margin-top:30px;
    }
     &.section-11{
       margin-top: 100px;
       .black{position: relative;
              height: 300px;
       .endurance-car{
         width: 120px;
         height: 250px;
         position: absolute;
         top: 50px;
         left: 305px;
       }
       .endurance-tyre{
         width: 250px;
       }
       .endurance-lf{
         position: absolute;
         left: 20px;
         top: 10px;
       }
       .endurance-rf{
       position: absolute;
       left: 465px;
       top: 10px;
     }
       .endurance-lb{
         position: absolute;
         left: 20px;
         top: 200px;
       }
       .endurance-rb{
       position: absolute;
       left: 465px;
       top: 200px;
     }

       }
     }
  }
  .noprint {
    position: absolute;
    left: 20px;
    top: 10px;
  }
  .title {
    display: flex;
    position: relative;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
    align-items: center;
    &::before {
      position: absolute;
      content: "";
      left: -14px;
      top: 50%;
      width: 3px;
      height: 80%;
      transform: translateY(-50%);
      background: #ffad26;
    }
  }
  .aucra{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .aucra-info{
      width: 450px;
      height: 100%;
      margin-bottom: 10px;
    }
  }
}

@page {
  size: A4 ;
  margin-top: 0px;
  content: "Our Cats";
}
@media print {
  body{
    -webkit-print-color-adjust: exact;
  }
  .report{
    height: 2480px;
    .card{
      height: 100%;
      .section-5.block {
        top: 10px;
      }
    }
  }
  .report-item.section-7{
    margin-top: 230px;
  }
  .noprint {
    display: none;
  }
}