.std{
  width: 100%;
  height: 330px;
  //position: relative;
  .st-up{
    position: relative;
  }
  .st-down{
    position:  relative;
    top:180px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    .a-num{
      display: inline-block;
      width: 22px;
      height: 22px;
      //background-color: #1abb2c;
      text-align: center;
      border-radius: 50%;
    }
     .b-num{
      position: absolute;
      display: inline-block;
      width: 22px;
      height: 22px;
      //background-color: #1abb2c;
      text-align: center;
      border-radius: 50%;
      left: 355px;
    }
    .c-num{
      position: absolute;
      display: inline-block;
      width: 22px;
      height: 22px;
      //background-color: #1abb2c;
      text-align: center;
      border-radius: 50%;
      left: -266px;
    }

    .st-do-le{
      text-align: left;
       position:  relative;
    }
    .st-do-ri{
      text-align: right;
       position:  relative;
    }

  }
}