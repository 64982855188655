@import '~antd/dist/antd.css';

#root{
  height: 100%;
  width: 100%;
}

@media print{
  #language{
    display: none;
  }
}
