 .ty-tl{
   position: relative;
   flex: 1;
   .ty-title {
    display: flex;
    position: relative;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
    align-items: center;
    &::before {
      position: absolute;
      content: "";
      left: -14px;
      top: 50%;
      width: 3px;
      height: 80%;
      transform: translateY(-50%);
      background: #ffad26;
    }
  }
 }


