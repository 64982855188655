.til-con{
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  background-color: #d2d2d2;
  margin-bottom: 5px;
  .til-le{
    display: inline-block;
    width: 3px;
    height: 16px;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 2px;
    background-color: #ffad26;

  }
  .til-ri{
    text-align: center;
    font-weight: bold;
  }
}