.tra-con{
  position: relative;
  width: 80%;
  margin: auto;
  height: 230px;
  p{
    width: 70px;
  }
  .tra-img{
    border: 0;
    width: 130px;
    height: 90px;
  }
}

.tra-lf{
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  width: 40%;
  position: absolute;
  top:10px;
  left:10px;
}

.tra-rf{
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  width: 40%;
  position: absolute;
  top: 10px;
  right: 10px;

}

.tra-lb{
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  width: 40%;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.tra-rb{
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  width: 40%;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.tra-con-cen{
  position: absolute;
  left: 43%;
  top: 33%;
}