.header{
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
  .he-content{
  width: 70%;
  display: flex;
  justify-content: space-between;
  .hr-tt{
    color:  #ffad26;
    font-weight: bold;
  }
}
  .qrcode{
    position: absolute;
    top: 10px;
    right: 0;
  }
}

