.conq{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 230px;

   .tle{
    font-weight: 700;
  }
  .tcn{
    font-size: 5px !important;
  }

  .conq-sp{
    display: inline-block;
    width: 70px;
    text-align: center;
    border-bottom: #929292 solid 1px;
  }

}